import { Button } from "components/form/Button";
import React from "react";

const Custom404 = () => {
	return (
		<main className="h-screen w-full flex flex-col justify-center items-center bg-gray-400">
			<h1 className="text-9xl font-extrabold text-white tracking-widest">
				404
			</h1>
			<div className="bg-jane-text px-2 text-sm rounded rotate-12 absolute">
				<span className="text-white">דף לא נמצא</span>
			</div>

			<Button
				className="mt-5"
				primary
				onClick={() => (window.location.href = "https://jane.co.il")}
			>
				חזרה ל - Jane.co.il
			</Button>
		</main>
	);
};

export default Custom404;
